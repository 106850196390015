import {createSelector} from "reselect";

const formData = state => state.formData;

export const selectLoading = createSelector(
    [formData],
    (formData) => formData.loading
);

export const selectError = createSelector(
    [formData],
    (formData) => formData.error
);

export const selectFormData = createSelector(
    [formData],
    (formData) => formData.formData
);

export const selectCurrentFormData = createSelector(
    [formData],
    (formData) => formData.current
);

export const selectFormConfiguration = createSelector(
    [formData],
    (formData) => formData.formConfiguration
);

export const selectDataDateRange = createSelector(
    [formData],
    (surveyForm) => surveyForm.dataDateRange
);
