import {
  Divider,
  Form,
  Radio,
  TreeSelect,
  Input,
  InputNumber,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getGeoLevels, getRegionsByLevelAndLevelUp } from "../../utilData";
import { useDispatch } from "react-redux";
import { GeometryAccuracy } from "../PlotsOptions/GeometryAccuracy";

/**
 * Creates the Form.Items for Locations in Analysis
 * @param props
 * @returns {*}
 * @constructor
 */
export const FormItemsLocations = (props) => {
  const {
    analysisType,
    configEdit,
    configEditFieldName,
    configView,
    edit,
    fieldsChanged,
    form,
    isDataSourceConfiguration,
    isNew,
    setLocationsObjectChanged,
    view,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const formItemComponentName = "locations";

  //*********************************************************************
  //reset location fields when project, type, pointMapType fields changes
  //*********************************************************************
  useEffect(
    function ue_resetFields() {
      if (fieldsChanged === null) return;
      if (fieldsChanged.length === 1) {
        const changedField = fieldsChanged.find((field) => {
          return (
            field.name.join("_") === ["configEdit", "project"].join("_") ||
            field.name.join("_") === ["configEdit", "type"].join("_") ||
            field.name.join("_") === ["configEdit", "pointMapType"].join("_")
          );
        });

        if (changedField) {
          form.resetFields([
            [configEditFieldName, formItemComponentName, "geoRegions_regions"],
            [configEditFieldName, formItemComponentName, "geoRegions_levelUp"],
            [configEditFieldName, formItemComponentName, "geoRegions_geoLevel"],
            [
              configEditFieldName,
              formItemComponentName,
              "subGeoRegions_subRegions_idLevels",
            ],
            [
              configEditFieldName,
              formItemComponentName,
              "geoRegions_selectModeRegions",
            ],
          ]);
        }
      }
    },
    //eslint-disable-next-line
    [fieldsChanged]
  );

  //*************************************************************************
  //end reset location fields when project, type, pointMapType fields changes
  //*************************************************************************

  //geoLevels and optionsGeolevels
  //---------------------------------------
  const [geoLevels, setGeoLevels] = useState();
  useEffect(
    function ue_setGeoLevels() {
      dispatch(getGeoLevels()).then((data) => {
        if (data) {
          setGeoLevels(
            data.geoLevels.sort((a, b) =>
              a.idLevel === b.idLevel ? 0 : a.idLevel < b.idLevel ? -1 : 1
            )
          );
        }
      });
    },
    //eslint-disable-next-line
    []
  );

  const [maxIdLevel, setMaxIdLevel] = useState(null);
  useEffect(
    function ue_setMaxIdLevel() {
      if (!geoLevels) return;

      setMaxIdLevel(geoLevels[geoLevels.length - 1].idLevel);
    },
    [geoLevels]
  );

  //******************************
  //location
  //****************************
  const setIdLevelObject_for_geoRegions = (idLevel, geoLevels, geoRegion) => {
    const geoLevel_current = geoLevels.find((geoLevel) => {
      const regExp = new RegExp("^" + geoLevel.regionCode + ".*$", "g");
      return geoLevel.idLevel === idLevel && regExp.test(geoRegion.regionCode);
    });

    return {
      idLevel: Number.parseInt(geoLevel_current?.idLevel),
      regionCode: geoLevel_current?.regionCode,
    };
  };
  async function async_getGeoRegionsPartial_Recursively(
    idLevel,
    levelUps,
    index = 0,
    geoRegions
  ) {
    return dispatch(getRegionsByLevelAndLevelUp(idLevel, levelUps[index])).then(
      (data) => {
        if (data?.geoRegions?.length > 0)
          geoRegions.push(
            ...data.geoRegions.map((geoRegion) => {
              geoRegion["geoLevel"] = setIdLevelObject_for_geoRegions(
                idLevel,
                geoLevels,
                geoRegion
              );
              return geoRegion;
            })
          );
        if (
          (index === 0 && levelUps.length === 0) ||
          index === levelUps.length - 1
        ) {
          return geoRegions;
        } else {
          return async_getGeoRegionsPartial_Recursively(
            idLevel,
            levelUps,
            index + 1,
            geoRegions
          );
        }
      }
    );
  }

  async function async_getGeoRegions_Recursively(
    idLevelMax,
    levelUpFilter = undefined,
    idLevel = 0,
    levelUps = [],
    geoRegions = []
  ) {
    if (idLevelMax === 0) {
      if (geoRegions.length === 0 && idLevel === 0) {
        return getRegionsByLevelAndLevelUp(0, null)()
          .then((data) => {
            if (data?.geoRegions?.length > 0)
              geoRegions.push(
                ...data.geoRegions.map((geoRegion) => {
                  geoRegion["geoLevel"] = setIdLevelObject_for_geoRegions(
                    0,
                    geoLevels,
                    geoRegion
                  );
                  return geoRegion;
                })
              );
            return geoRegions;
          })
          .then((geoRegions) => {
            const nextLevelUps =
              levelUpFilter !== undefined
                ? levelUps.filter((levelUp) => {
                    return levelUpFilter.includes(levelUp);
                  })
                : levelUps;
            return async_getGeoRegions_Recursively(
              idLevelMax,
              levelUpFilter,
              idLevel + 1,
              nextLevelUps,
              geoRegions
            );
          });
      } else {
        return geoRegions;
      }
    } else {
      if (idLevel > idLevelMax) {
        return geoRegions;
      } else {
        return async_getGeoRegionsPartial_Recursively(
          idLevel,
          levelUps,
          0,
          geoRegions
        ).then((geoRegions) => {
          let nextLevelUps = [];
          nextLevelUps.push(
            ...geoRegions.map((geoRegion) => geoRegion.regionCode)
          );
          nextLevelUps = nextLevelUps.filter((nextLevelUp) =>
            levelUpFilter !== undefined
              ? levelUpFilter.includes(nextLevelUp)
              : true
          );
          return async_getGeoRegions_Recursively(
            idLevelMax,
            levelUpFilter,
            idLevel + 1,
            nextLevelUps,
            geoRegions
          );
        });
      }
    }
  }

  const setTreeDataGeoRegions_Recursively_new = () => {
    async_getGeoRegions_Recursively(0).then((geoRegions) => {
      setTreeDataGeoRegions(() => {
        return geoRegions
          .map((geoRegion) => {
            const isLeaf = geoRegion.idLevel === maxIdLevel - 1;
            return node2item(geoRegion, {
              isLeaf: isLeaf,
              isSelectable: true,
              isDisabled: false,
            });
          })
          .sort((a, b) =>
            a.title === b.title ? 0 : a.title < b.title ? -1 : 1
          );
      });
    });
  };

  const setTreeDataGeoRegions_Recursively_edit = (
    idLevelMax,
    levelUpFilter
  ) => {
    async_getGeoRegions_Recursively(idLevelMax, levelUpFilter).then(
      (geoRegions) => {
        const selectedRegionsLevelUp = configEdit.locations.geoRegions_levelUp;
        setTreeDataGeoRegions(() => {
          return geoRegions
            .filter((geoRegion) => {
              const siblings =
                geoRegion.regionCodeLevelUp === selectedRegionsLevelUp;
              const regExp = new RegExp(geoRegion.regionCode + ".*", "g");
              const parents = regExp.test(selectedRegionsLevelUp);
              const others =
                geoRegion.regionCode?.length <= selectedRegionsLevelUp?.length;
              return siblings || parents || others;
            })
            .map((geoRegion) => {
              let isLeaf, isSelectable, isDisabled;
              const siblings =
                geoRegion.regionCodeLevelUp === selectedRegionsLevelUp;
              const regExp = new RegExp(geoRegion.regionCode + ".*", "g");
              const parents = regExp.test(selectedRegionsLevelUp);
              const others =
                geoRegion.regionCode.length <= selectedRegionsLevelUp.length;

              if (siblings) {
                isLeaf = true;
                isSelectable =
                  configEdit.locations.geoRegions_selectMode !== "all";
                isDisabled = false;
              }
              if (parents) {
                isLeaf = false;
                isSelectable = false;
                isDisabled = true;
              }
              if (others && !parents) {
                isLeaf = true;
                isSelectable = false;
                isDisabled = true;
              }

              return node2item(geoRegion, {
                isLeaf: isLeaf,
                isSelectable: isSelectable,
                isDisabled: isDisabled,
              });
            })
            .sort((a, b) =>
              a.title === b.title ? 0 : a.title < b.title ? -1 : 1
            );
        });

        setTreeExpandedKeysRegions(() => {
          const expandedKeysGeoRegions = geoRegions.filter((geoRegion) => {
            //only parent nodes are expanded
            const regExp = new RegExp(geoRegion.regionCode + ".*", "g");
            return regExp.test(selectedRegionsLevelUp);
          });
          if (expandedKeysGeoRegions !== -1) {
            return expandedKeysGeoRegions.map(
              (geoRegion) => geoRegion.regionCode
            );
          } else {
            return null;
          }
        });
      }
    );
  };

  const setTreeDataGeoRegions_Recursively_view = (
    idLevelMax,
    levelUpFilter
  ) => {
    async_getGeoRegions_Recursively(idLevelMax, levelUpFilter).then(
      (geoRegions) => {
        const selectedRegionCodesEdit =
          configEdit.locations?.geoRegions_regions;
        const selectedRegionsLevelUpView =
          configView?.locations.geoRegions_levelUp;
        setTreeDataGeoRegions(() => {
          return geoRegions
            .filter((geoRegion) => {
              return selectedRegionCodesEdit?.some((selectedRegionCode) => {
                const regExp = new RegExp(geoRegion.regionCode + ".*", "g");
                return regExp.test(selectedRegionCode);
              });
            })
            .map((geoRegion) => {
              let isLeaf, isSelectable, isDisabled;
              let siblings, parents, others;

              if (configView !== null) {
                siblings =
                  geoRegion.regionCodeLevelUp === selectedRegionsLevelUpView;
                const regExp = new RegExp(geoRegion.regionCode + ".*", "g");
                parents = regExp.test(selectedRegionsLevelUpView);
                others =
                  geoRegion.regionCode.length <=
                  selectedRegionsLevelUpView.length;
                if (siblings) {
                  isLeaf = true;
                  isSelectable =
                    configView.locations.geoRegions_selectMode !== "all";
                  isDisabled = false;
                }
                if (parents) {
                  isLeaf = false;
                  isSelectable = false;
                  isDisabled = true;
                }
                if (others && !parents) {
                  isLeaf = true;
                  isSelectable = false;
                  isDisabled = true;
                }
              } else {
                const isRegionCodeIncluded = selectedRegionCodesEdit.includes(
                  geoRegion.regionCode
                );
                isLeaf = isRegionCodeIncluded;
                isSelectable = isRegionCodeIncluded;
                isDisabled = !isRegionCodeIncluded;
              }

              return node2item(geoRegion, {
                isLeaf: isLeaf,
                isSelectable: isSelectable,
                isDisabled: isDisabled,
              });
            })
            .sort((a, b) =>
              a.title === b.title ? 0 : a.title < b.title ? -1 : 1
            );
        });

        setTreeExpandedKeysRegions(() => {
          if (configView === null) return null;

          const expandedKeysGeoRegions = geoRegions.filter((geoRegion) => {
            //only parent nodes are expanded
            const regExp = new RegExp(geoRegion.regionCode + ".*", "g");
            return regExp.test(selectedRegionsLevelUpView);
          });
          if (expandedKeysGeoRegions !== -1) {
            return expandedKeysGeoRegions.map(
              (geoRegion) => geoRegion.regionCode
            );
          } else {
            return null;
          }
        });
      }
    );
  };

  const [treeDataGeoRegions, setTreeDataGeoRegions] = useState(null);
  const setValue_in_setTreeDataSubGeoRegions = (
    geoLevel,
    idLevelCase = "current"
  ) => {
    return JSON.stringify({
      regionCode: geoLevel.regionCode,
      idLevel:
        idLevelCase === "current"
          ? geoLevel.idLevel
          : geoLevel.idLevel === 0
          ? null
          : geoLevel.idLevel - 1,
    });
  };

  useEffect(
    function ue_setTreeDataGeoRegions() {
      if (maxIdLevel === null) return;

      if (!view && !edit) {
        //new analysis
        setTreeDataGeoRegions_Recursively_new();
      }

      if (!view && edit) {
        //edit analysis
        //regions
        const geoRegions_geoLevel = configEdit?.locations?.geoLevel;
        let idLevelMaxRegions;
        //THIS TRY CATCH STATEMENT IS FOR MANAGING THE TRANSITION OF ANALYSIS FROM
        //typeof geoRegions_geoLevel === 'number' to
        //typeof geoRegions_geoLevel === 'object'
        if (typeof geoRegions_geoLevel === "object") {
          idLevelMaxRegions = geoRegions_geoLevel.idLevel
            ? geoRegions_geoLevel.idLevel
            : 0;
        } else {
          message.warning("Analysis' Locations must be updated!");

          setTreeDataGeoRegions_Recursively_new();
          return;
        }

        const levelUpFilter = configEdit?.locations?.geoRegions_levelUp
          ? configEdit.locations.geoRegions_levelUp
          : "";
        setTreeDataGeoRegions_Recursively_edit(
          idLevelMaxRegions,
          levelUpFilter
        );

        //subRegions
        setTreeDataSubGeoRegions(() => {
          return geoLevels.map((geoLevel) => {
            const idLevel = geoLevel.idLevel;
            const value = setValue_in_setTreeDataSubGeoRegions(geoLevel);
            const pId = setValue_in_setTreeDataSubGeoRegions(
              geoLevel,
              "parent"
            );
            return {
              label: geoLevel.name,
              value: value,
              key: value,
              id: value,
              pId: pId,
              disabled: idLevel <= idLevelMaxRegions,
            };
          });
        });
      }

      if (view) {
        //view analysis
        //regions
        if (configEdit?.locations === undefined) return;
        const geoRegions_geoLevel = configEdit?.locations?.geoLevel;
        let idLevelMaxRegions;
        if (typeof geoRegions_geoLevel === "object") {
          idLevelMaxRegions = geoRegions_geoLevel.idLevel
            ? geoRegions_geoLevel.idLevel
            : 0;
        } else {
          message.error(
            "Analysis' Locations must be updated before viewing it!"
          );
          return;
        }
        const levelUpFilter = configEdit?.locations?.geoRegions_levelUp
          ? configEdit.locations.geoRegions_levelUp
          : "";

        setTreeDataGeoRegions_Recursively_view(
          idLevelMaxRegions,
          levelUpFilter
        );

        //subRegions
        setTreeDataSubGeoRegions(() => {
          const subRegionsPossibleIdLevels =
            configEdit.locations?.subGeoRegions_subRegions_idLevels;
          return geoLevels.map((geoLevel) => {
            const value = setValue_in_setTreeDataSubGeoRegions(geoLevel);
            const pId = setValue_in_setTreeDataSubGeoRegions(
              geoLevel,
              "parent"
            );
            return {
              label: geoLevel.name,
              value: value,
              key: value,
              id: value,
              pId: pId,
              disabled: !subRegionsPossibleIdLevels?.includes(value),
            };
          });
        });
      }
    },
    //eslint-disable-next-line
    [analysisType, view, edit, maxIdLevel]
  );

  //selectModeRegions state
  //-------------------
  const [selectModeRegions, setSelectModeRegions] = useState(() => {
    if (!edit && !view) return "selection";
    if (edit && !view) return configEdit.locations.geoRegions_selectModeRegions;
    if (view)
      return configView
        ? configView.locations.geoRegions_selectModeRegions
        : "selection";
  });
  useEffect(
    function ue_setSelectModeRegions() {
      if (fieldsChanged === null) return;
      if (fieldsChanged.length !== 1) return;
      const changedField = fieldsChanged.find((field) =>
        field.name.includes("geoRegions_selectModeRegions")
      );
      if (changedField) {
        setSelectModeRegions(changedField.value);
      }
    },
    //eslint-disable-next-line
    [fieldsChanged]
  );

  const node2item = (node, obj = {}) => {
    const defaultSelectable = true;
    const defaultIsLeaf = false;
    const defaultDisabled = false;
    return {
      title: node.name,
      value: node.regionCode,
      id: node.regionCode,
      pId: node.regionCodeLevelUp,
      selectable: obj?.isSelectable ? obj.isSelectable : defaultSelectable,
      isLeaf: obj?.isLeaf ? obj.isLeaf : defaultIsLeaf,
      disabled: obj?.isDisabled ? obj.isDisabled : defaultDisabled,
      key: node.regionCode,
      geoLevel: node.geoLevel,
    };
  };

  useEffect(
    function ue_selectModeRegions_state_change() {
      if (!treeDataGeoRegions || !selectModeRegions) return;
      const selectedRegionsCodes = form.getFieldValue([
        configEditFieldName,
        formItemComponentName,
        "geoRegions_regions",
      ]);

      if (selectedRegionsCodes?.length > 0) {
        const regionCodeLevelUp = treeDataGeoRegions.find(
          (item) => item.id === selectedRegionsCodes[0]
        )?.pId;
        switch (selectModeRegions) {
          case "all":
            const allRegionsCodesForRegionCodeLevelUp = treeDataGeoRegions
              .filter((item) => item.pId === regionCodeLevelUp)
              .map((item) => item.id);
            form.setFieldsValue({
              [configEditFieldName]: {
                [formItemComponentName]: {
                  geoRegions_regions: allRegionsCodesForRegionCodeLevelUp,
                },
              },
            });

            setTreeDataGeoRegions((prevTreeData) => {
              return prevTreeData.map((item) => {
                item.selectable = false;
                if (item.pId === regionCodeLevelUp) {
                  item.isLeaf = true;
                  item.disabled = false;
                } else {
                  item.isLeaf = true;
                  item.disabled = true;
                }

                return item;
              });
            });
            break;
          case "selection":
            setTreeDataGeoRegions((prevTreeData) => {
              return prevTreeData.map((item) => {
                if (item.pId === regionCodeLevelUp) {
                  item.selectable = true;
                  item.isLeaf = true;
                  item.disabled = false;
                } else {
                  item.selectable = false;
                  item.isLeaf = true;
                  item.disabled = true;
                }

                return item;
              });
            });
            break;
          default:
          //do nothing
        }
      }
    },
    //eslint-disable-next-line
    [selectModeRegions]
  );

  //managing user interaction
  //-------------------------

  //-----------------------------------
  //changedField - geoRegions_regions
  //-----------------------------------
  const [treeExpandedKeysRegions, setTreeExpandedKeysRegions] = useState(null);
  const [treeDataSubGeoRegions, setTreeDataSubGeoRegions] = useState(null);
  useEffect(
    function ue_changedField_geoRegions_regions() {
      if (fieldsChanged === null) return;
      if (fieldsChanged.length > 1 || !treeDataGeoRegions) return;

      const changedField = fieldsChanged.find((field) =>
        field.name.includes("geoRegions_regions")
      );

      if (!changedField) return;

      let locationsObjectChanged;

      switch (changedField.value?.length) {
        case 0:
          //update regions
          setTreeDataGeoRegions((prevTreeData) => {
            return prevTreeData.map((item) => {
              if (!edit && !view) {
                item.isLeaf = item.geoLevel.idLevel === maxIdLevel - 1;
                item.selectable = true;
                item.disabled = false;
              }
              if (edit && !view) {
                item.isLeaf = item.geoLevel.idLevel === maxIdLevel - 1;
                item.selectable = true;
                item.disabled = false;
              }
              if (view) {
                const regionCodeLength =
                  configEdit.locations.geoRegions_regions[0].length;
                const item_regionCodeLength = item.id.length;
                item.isLeaf = item_regionCodeLength === regionCodeLength;
                item.selectable = item_regionCodeLength === regionCodeLength;
                item.disabled = item_regionCodeLength < regionCodeLength;
              }

              return item;
            });
          });
          setTreeExpandedKeysRegions(null);
          form.resetFields([
            [configEditFieldName, formItemComponentName, "geoRegions_levelUp"],
          ]);
          form.resetFields([
            [configEditFieldName, formItemComponentName, "geoRegions_geoLevel"],
          ]);

          //locationsObjectChanged
          locationsObjectChanged = {
            geoRegions_levelUp: undefined,
            geoRegions_geoLevel: undefined,
            geoRegions_regions: changedField.value,
          };

          //update subRegions
          setTreeDataSubGeoRegions(null);
          form.resetFields([
            [
              configEditFieldName,
              formItemComponentName,
              "subGeoRegions_subRegions_idLevels",
            ],
          ]);
          break;
        case 1:
          //update regions
          const selectedRegionCode = changedField.value[0];
          const selectedRegion = treeDataGeoRegions.find(
            (item) => item.id === selectedRegionCode
          );
          const selectedRegionLevelUp = selectedRegion.pId;
          const selectedRegionIdLevel = selectedRegion.geoLevel.idLevel;
          setTreeDataGeoRegions((prevTreeData) => {
            return prevTreeData.map((item) => {
              let isLeaf = true;
              let isSelectable = false;
              let isDisabled = true;

              const siblings = item.pId === selectedRegionLevelUp;
              const regExp = new RegExp(item.id + ".*", "g");
              const parents = regExp.test(selectedRegionLevelUp);
              const others = item.id.length <= selectedRegionLevelUp.length;

              let selectMode;
              if (!edit && !view) {
                selectMode = selectModeRegions;
              }

              if ((edit && !view) || view) {
                selectMode = configEdit.locations.geoRegions_selectMode;
              }

              if (siblings) {
                isLeaf = true;
                isSelectable = selectMode !== "all";
                isDisabled = false;
              }
              if (parents) {
                isLeaf = false;
                isSelectable = false;
                isDisabled = true;
              }
              if (others && !parents) {
                isLeaf = true;
                isSelectable = false;
                isDisabled = true;
              }

              item.isLeaf = isLeaf;
              item.selectable = isSelectable;
              item.disabled = isDisabled;

              return item;
            });
          });

          //locationsObjectChanged
          locationsObjectChanged = {
            geoRegions_levelUp: selectedRegionLevelUp,
            geoRegions_geoLevel: selectedRegion.geoLevel,
            geoRegions_regions: changedField.value,
          };

          if (selectModeRegions === "all") {
            const allRegCodes = treeDataGeoRegions
              .map((item) => {
                if (item.pId === selectedRegionLevelUp) {
                  return item.id;
                } else {
                  return null;
                }
              })
              .filter((item) => item !== null);
            form.setFieldsValue({
              [configEditFieldName]: {
                [formItemComponentName]: {
                  geoRegions_regions: allRegCodes,
                },
              },
            });

            //locationsObjectChanged update geoRegions_regions
            locationsObjectChanged["geoRegions_regions"] = allRegCodes;
          }

          setTreeExpandedKeysRegions(() => {
            return treeDataGeoRegions
              .filter((item) => item.id.length < selectedRegionCode.length)
              .map((item) => item.id);
          });

          form.setFieldsValue({
            [configEditFieldName]: {
              [formItemComponentName]: {
                geoRegions_levelUp: selectedRegionLevelUp,
              },
            },
          });

          form.setFieldsValue({
            [configEditFieldName]: {
              [formItemComponentName]: {
                geoRegions_geoLevel: selectedRegion.geoLevel,
              },
            },
          });

          //update subRegions
          setTreeDataSubGeoRegions(() => {
            let subRegionsPossibleIdLevels = view
              ? configEdit.locations.subGeoRegions_subRegions_idLevels
              : null;
            return geoLevels.map((geoLevel) => {
              const idLevel = geoLevel.idLevel;
              const value = setValue_in_setTreeDataSubGeoRegions(geoLevel);
              const pId = setValue_in_setTreeDataSubGeoRegions(
                geoLevel,
                "parent"
              );
              return {
                label: geoLevel.name,
                value: value,
                key: value,
                id: value,
                pId: pId,
                disabled: view
                  ? !subRegionsPossibleIdLevels?.includes(value)
                  : idLevel <= selectedRegionIdLevel,
              };
            });
          });
          form.resetFields([
            [
              configEditFieldName,
              formItemComponentName,
              "subGeoRegions_subRegions_idLevels",
            ],
          ]);
          break;
        default: //changedField.value?.length > 1
          //Note: in this case, idLevel and levelUp did not change, because their values are set when the first
          //geoRegion is selected
          //update regions
          const selectedRegionsCodes = changedField.value;

          //setting regionCodeLevelUp
          const regionCodeLevelUp = treeDataGeoRegions.find(
            (item) => item.id === selectedRegionsCodes[0]
          ).pId;

          //locationsObjectChanged
          locationsObjectChanged = {
            geoRegions_levelUp: undefined,
            geoRegions_geoLevel: undefined,
            geoRegions_regions: changedField.value,
          };

          //for selectModeRegions === 'all' keep all values in select input box
          if (selectModeRegions === "all") {
            const allRegCodes = treeDataGeoRegions
              .map((item) => {
                return item.pId === regionCodeLevelUp ? item.id : null;
              })
              .filter((item) => item !== null);
            form.setFieldsValue({
              [configEditFieldName]: {
                [formItemComponentName]: {
                  geoRegions_regions: allRegCodes,
                },
              },
            });

            //locationsObjectChanged update geoRegions_regions
            locationsObjectChanged["geoRegions_regions"] = allRegCodes;
          }

          //updating subRegions
          setTreeDataSubGeoRegions(() => {
            const selectedRegionsIdLevel = treeDataGeoRegions.find(
              (item) => item.id === selectedRegionsCodes[0]
            ).geoLevel.idLevel;
            let subRegionsPossibleIdLevels = view
              ? configEdit.locations.subGeoRegions_subRegions_idLevels
              : null;
            return geoLevels.map((geoLevel) => {
              const idLevel = geoLevel.idLevel;
              const value = setValue_in_setTreeDataSubGeoRegions(geoLevel);
              const pId = setValue_in_setTreeDataSubGeoRegions(
                geoLevel,
                "parent"
              );
              return {
                label: geoLevel.name,
                value: value,
                key: value,
                id: value,
                pId: pId,
                disabled: view
                  ? !subRegionsPossibleIdLevels?.includes(value)
                  : idLevel <= selectedRegionsIdLevel,
              };
            });
          });
          form.resetFields([
            [
              configEditFieldName,
              formItemComponentName,
              "subGeoRegions_subRegions_idLevels",
            ],
          ]);
      }

      if (setLocationsObjectChanged && locationsObjectChanged !== undefined) {
        setLocationsObjectChanged(locationsObjectChanged);
      }
    },
    //eslint-disable-next-line
    [fieldsChanged]
  );
  //-------------------------------------
  //end changedField - geoRegions_regions
  //-------------------------------------

  //----------------------------
  //handleLoadDataRegions
  //----------------------------
  const handleLoadDataRegions = ({ id }) =>
    new Promise((resolve) => {
      let requiredGeoRegionsView;
      if (view)
        requiredGeoRegionsView = configEdit.locations.geoRegions_regions;
      const idLevel =
        treeDataGeoRegions.find((item) => item.id === id).geoLevel.idLevel + 1;
      // TODO:
      //const currentMaxIdLevel = ['PointMap','HeatMap'].includes(analysisType)
      //    ? maxIdLevel : (maxIdLevel-1);
      const currentMaxIdLevel = maxIdLevel - 1;
      const isLeaf = idLevel === currentMaxIdLevel;
      getRegionsByLevelAndLevelUp(idLevel, id)()
        .then((data) => {
          const geoRegions = data.geoRegions.map((geoRegion) => {
            geoRegion["geoLevel"] = setIdLevelObject_for_geoRegions(
              idLevel,
              geoLevels,
              geoRegion
            );
            return geoRegion;
          });
          return geoRegions;
        })
        .then((geoRegions) => {
          if (geoRegions) {
            setTreeDataGeoRegions((prevTreeData) => {
              const requiredNodes = geoRegions.filter((geoRegion) => {
                const notInPrevTreeData = !prevTreeData.find(
                  (item) => item.id === geoRegion.regionCode
                );
                const selectedInView = requiredGeoRegionsView
                  ? requiredGeoRegionsView.includes(geoRegion.regionCode)
                  : true;
                return notInPrevTreeData && selectedInView;
              });
              let newTreeData = [...prevTreeData];
              if (requiredNodes !== -1) {
                newTreeData.push(
                  ...requiredNodes.map((node) => {
                    return node2item(node, { isLeaf: isLeaf });
                  })
                );
              }
              return newTreeData.sort((a, b) =>
                a.title === b.title ? 0 : a.title < b.title ? -1 : 1
              );
            });
            resolve();
          }
        });
    });
  //----------------------------
  //end handleLoadDataRegions
  //----------------------------

  const [onlyOneItemToSelect, setOnlyOneItemToSelect] = useState(view);
  useEffect(() => {
    if (!view || !treeDataGeoRegions) return;

    setOnlyOneItemToSelect(() => {
      const numberOfLeafs = treeDataGeoRegions
        .map((item) => item.id.length)
        .filter(
          (value, index, itemsLengths) => value === Math.max(...itemsLengths)
        );

      return numberOfLeafs?.length === 1;
    });
  }, [treeDataGeoRegions, view]);

  //******************************
  //end location
  //******************************

  //***************************************
  //Hiding Locations component in view mode
  //**************************************
  const [hide_geoRegions_regions_field, setHide_geoRegions_regions_field] =
    useState(view);
  const [
    hide_subGeoRegions_subRegions_idLevels_field,
    setHide_subGeoRegions_subRegions_idLevels_field,
  ] = useState(view);
  const [
    display_subGeoRegions_subRegions_idLevels_FormItem,
    setDisplay_subGeoRegions_subRegions_idLevels_FormItem,
  ] = useState(true);
  const [canSetHideAndDisplay, setCanSetHideAndDisplay] = useState(false);
  useEffect(
    function ue_setCanSetHideAndDisplay() {
      if (view && treeDataGeoRegions && treeDataSubGeoRegions) {
        //exception
        const noRegion =
          treeDataGeoRegions.filter((gR) => !gR.disabled).length === 0;
        if (noRegion) return;

        setCanSetHideAndDisplay(true);
      }
    },
    //eslint-disable-next-line
    [view, treeDataGeoRegions, treeDataSubGeoRegions]
  );

  /**
   * Sets form fields for geoRegions_regions or subGeoRegions_subRegions_idLevels
   * @param regionType - Can take one of the values:  'geoRegions_regions' or 'subGeoRegions_subRegions_idLevels'
   * @param treeDataType - Can take one of the values: treeDataGeoRegions or treeDataSubGeoRegions
   */
  const setRegionsSubRegionsFields = (regionType, treeDataType) => {
    let configEdit_ = form.getFieldValue([configEditFieldName]);
    let locations_ = configEdit_.locations;
    const geoRegion = treeDataType.filter((gR) => !gR.disabled)[0];
    locations_[regionType] =
      regionType === "geoRegions_regions" ? [geoRegion.value] : geoRegion.value;
    //set regionType field
    configEdit_.locations = locations_;
    form.setFieldsValue({
      [configEditFieldName]: configEdit_,
    });
  };
  useEffect(
    function ue_set_Hide_And_Display_fields() {
      if (!canSetHideAndDisplay) return;

      const oneRegion =
        treeDataGeoRegions.filter((gR) => !gR.disabled).length === 1;
      const noSubRegions =
        treeDataSubGeoRegions.filter((sGR) => !sGR.disabled).length === 0;
      const oneSubRegion =
        treeDataSubGeoRegions.filter((sGR) => !sGR.disabled).length === 1;

      if (oneRegion && (noSubRegions || oneSubRegion)) {
        //hide geoRegions_regions field
        setHide_geoRegions_regions_field(true);

        if (noSubRegions) {
          //don't display subGeoRegions_subRegions_idLevels field
          setDisplay_subGeoRegions_subRegions_idLevels_FormItem(false);
        }

        if (oneSubRegion) {
          //hide subGeoRegions_subRegions_idLevels field
          setHide_subGeoRegions_subRegions_idLevels_field(true);
          //display subGeoRegions_subRegions_idLevels field
          setDisplay_subGeoRegions_subRegions_idLevels_FormItem(true);
        }

        //set geoRegions_regions field
        setRegionsSubRegionsFields("geoRegions_regions", treeDataGeoRegions);

        if (oneSubRegion) {
          //set subGeoRegions_subRegions_idLevels field
          setRegionsSubRegionsFields(
            "subGeoRegions_subRegions_idLevels",
            treeDataSubGeoRegions
          );
        }
      }

      if (oneRegion && !noSubRegions && !oneSubRegion) {
        //hide geoRegions_regions field
        setHide_geoRegions_regions_field(false);
        //hide subGeoRegions_subRegions_idLevels field
        setHide_subGeoRegions_subRegions_idLevels_field(false);
        //display subGeoRegions_subRegions_idLevels field
        setDisplay_subGeoRegions_subRegions_idLevels_FormItem(true);

        //set geoRegions_regions field
        setRegionsSubRegionsFields("geoRegions_regions", treeDataGeoRegions);
      }

      if (!oneRegion) {
        //don't hide geoRegions_regions field
        setHide_geoRegions_regions_field(false);

        if (noSubRegions) {
          //don't display subGeoRegions_subRegions_idLevels field
          setDisplay_subGeoRegions_subRegions_idLevels_FormItem(false);
        }

        if (oneSubRegion) {
          //hide subGeoRegions_subRegions_idLevels field
          setHide_subGeoRegions_subRegions_idLevels_field(false);
          //display subGeoRegions_subRegions_idLevels field
          setDisplay_subGeoRegions_subRegions_idLevels_FormItem(true);

          //set subGeoRegions_subRegions_idLevels field
          setRegionsSubRegionsFields(
            "subGeoRegions_subRegions_idLevels",
            treeDataSubGeoRegions
          );
        }

        if (!noSubRegions && !oneSubRegion) {
          //don't hide subGeoRegions_subRegions_idLevels field
          setHide_subGeoRegions_subRegions_idLevels_field(false);
          //display subGeoRegions_subRegions_idLevels field
          setDisplay_subGeoRegions_subRegions_idLevels_FormItem(true);
        }
      }
    },
    //eslint-disable-next-line
    [canSetHideAndDisplay]
  );

  //*******************************
  //End Hiding Locations component
  //*******************************

  //jsx
  return (
    <div id={"selectLocations"}>
      <>
        {view ? null : (
          <Form.Item
            wrapperCol={{ span: isDataSourceConfiguration ? null : 17 }}
          >
            <Divider orientation={"left"}>
              {intl.formatMessage({
                id: isDataSourceConfiguration
                  ? "FormItemActiveWeatherStationsId.label.filterBy.geoRegions"
                  : "label.FormItemsLocations.divider",
              })}
            </Divider>
          </Form.Item>
        )}
        <Form.Item
          label={
            !view
              ? intl.formatMessage({
                  id: "label.FormItemsLocations.GeographicalAreas",
                })
              : null
          }
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 13 }}
          hidden={
            view &&
            hide_geoRegions_regions_field &&
            hide_subGeoRegions_subRegions_idLevels_field
          }
        >
          <Form.Item>
            <Form.Item
              label={
                [
                  "ChoroplethMap",
                  "BarPlot",
                  "TimeSeries",
                  "PointMap",
                  "BubbleMap",
                ].includes(analysisType)
                  ? intl.formatMessage({
                      id: "label.FormItemsLocations.GeoRegions.Regions",
                    })
                  : null
              }
              name={[
                configEditFieldName,
                formItemComponentName,
                "geoRegions_regions",
              ]}
              initialValue={undefined}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.Locations.GeoRegions.Regions",
                  }),
                },
              ]}
              style={{ marginBottom: 0 }}
              hidden={view && hide_geoRegions_regions_field}
            >
              <TreeSelect
                treeDataSimpleMode
                style={{ width: "100%" }}
                showSearch
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: view
                    ? "label.FormItemsLocations.GeoRegions.Regions.TreeSelect.placeholder.view"
                    : "label.FormItemsLocations.GeoRegions.Regions.TreeSelect.placeholder",
                })}
                multiple={true}
                treeDefaultExpandAll={view}
                loadData={handleLoadDataRegions}
                treeData={treeDataGeoRegions}
                treeExpandedKeys={treeExpandedKeysRegions}
                treeNodeFilterProp={"title"}
                maxTagCount={"responsive"}
              />
            </Form.Item>
            <Form.Item
              name={[
                configEditFieldName,
                formItemComponentName,
                "geoRegions_selectModeRegions",
              ]}
              style={{ marginBottom: 0, marginTop: 0 }}
              initialValue={"selection"}
              hidden={
                onlyOneItemToSelect || (view && hide_geoRegions_regions_field)
              }
            >
              <Radio.Group>
                <Radio value={"all"}>
                  {intl.formatMessage({
                    id: "label.FormItemsLocations.GeoRegions.selectModeRegions.all",
                  })}
                </Radio>
                <Radio value={"selection"}>
                  {intl.formatMessage({
                    id: "label.FormItemsLocations.GeoRegions.selectModeRegions.selection",
                  })}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form.Item>
          <Form.Item
            style={{
              display:
                ["ChoroplethMap", "BarPlot"].includes(analysisType) &&
                (isNew ||
                  (edit && !view) ||
                  (view && display_subGeoRegions_subRegions_idLevels_FormItem))
                  ? null
                  : "none",
              height:
                view && hide_subGeoRegions_subRegions_idLevels_field ? 0 : null,
              margin:
                view && hide_subGeoRegions_subRegions_idLevels_field ? 0 : null,
            }}
          >
            <Form.Item
              label={intl.formatMessage({
                id: view
                  ? "label.FormItemsLocations.SubGeoRegions.SubRegion"
                  : "label.FormItemsLocations.SubGeoRegions.SubRegions",
              })}
              name={[
                configEditFieldName,
                formItemComponentName,
                "subGeoRegions_subRegions_idLevels",
              ]}
              initialValue={undefined}
              rules={[
                {
                  required: ["ChoroplethMap", "BarPlot"].includes(analysisType),
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.Locations.SubGeoRegions.SubRegions",
                  }),
                },
              ]}
              dependencies={[
                [
                  configEditFieldName,
                  formItemComponentName,
                  "geoRegions_regions",
                ],
              ]}
              style={{ marginBottom: 0 }}
              hidden={view && hide_subGeoRegions_subRegions_idLevels_field}
            >
              <TreeSelect
                treeDataSimpleMode
                style={{ width: "100%" }}
                showSearch
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: view
                    ? "label.FormItemsLocations.SubGeoRegions.SubRegions.TreeSelect.placeholder.view"
                    : "label.FormItemsLocations.SubGeoRegions.SubRegions.TreeSelect.placeholder",
                })}
                multiple={!view}
                treeDefaultExpandAll={true}
                treeData={treeDataSubGeoRegions}
                treeNodeFilterProp={"title"}
                maxTagCount={"responsive"}
                disabled={(() => {
                  const regionsField = form.getFieldValue([
                    configEditFieldName,
                    formItemComponentName,
                    "geoRegions_regions",
                  ]);
                  return !regionsField || regionsField?.length === 0;
                })()}
                showCheckedStrategy={TreeSelect.SHOW_ALL}
              />
            </Form.Item>
          </Form.Item>
          {["BarPlot", "TimeSeries"].includes(analysisType) ? (
            <GeometryAccuracy
              formItemName={[
                configEditFieldName,
                formItemComponentName,
                "geometryAccuracy",
              ]}
              hidden={view}
            />
          ) : null}
          <Form.Item
            name={[
              configEditFieldName,
              formItemComponentName,
              "geoRegions_levelUp",
            ]}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "msg.input-required.Analysis.Locations.GeoRegions.LevelUp",
                }),
              },
            ]}
            hidden={true}
            initialValue={undefined}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={[
              configEditFieldName,
              formItemComponentName,
              "geoRegions_geoLevel",
            ]}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "msg.input-required.Analysis.Locations.GeoRegions.geoLevel",
                }),
              },
            ]}
            hidden={true}
            initialValue={undefined}
          >
            <InputNumber />
          </Form.Item>
        </Form.Item>
      </>
    </div>
  );
};
