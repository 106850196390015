import axiosInstance from "../../../utils/axiosInstance";
import { FORM_DATA_ACTION_TYPES } from "../../typesAction";
import { Image, Popconfirm, Tag } from "antd";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import { FORM_FIELD_TYPES } from "../../../utils/formTypes";

export const setLoading = () => {
  return { type: FORM_DATA_ACTION_TYPES.LOAD_FORM_DATA };
};

export const clearLoading = () => {
  return { type: FORM_DATA_ACTION_TYPES.CLEAR_LOAD_FORM_DATA };
};

export const clearFormDataError = () => {
  return { type: FORM_DATA_ACTION_TYPES.CLEAR_FORM_DATA_ERRORS };
};

export const listFormData =
  (
    setColumns,
    intl,
    handleDelete,
    formId,
    history,
    pagination,
    filters = {},
    sorter
  ) =>
  async (dispatch) => {
    try {
      dispatch(setLoading());
      // Prepare all query parameters
      let queryParameters = {};
      Object.keys(filters).map(
        (key) =>
          (queryParameters[key] =
            filters[key] == null ? filters[key] : filters[key][0])
      );
      if (sorter !== undefined) {
        queryParameters["sort"] = sorter["field"];
        queryParameters["order"] = sorter["order"];
      }
      if (pagination !== undefined) {
        queryParameters["offset"] =
          (pagination.current - 1) * pagination.pageSize;
        queryParameters["max"] = pagination.pageSize;
      }
      // Spread the parameters
      const params = {
        ...queryParameters,
      };
      // Send request and get data
      const { data } = await axiosInstance(history, dispatch).get(
        `/form/${formId}/data`,
        { params }
      );
      // Build table
      const columns = [
        {
          name: intl.formatMessage({ id: "label.id" }),
          selector: (row) => row.id,
          center: true,
          compact: true,
          allowOverflow: false,
          grow: 1,
          sortable: true,
          sortField: "id",
        },
        {
          name: intl.formatMessage({ id: "label.actions" }),
          center: true,
          sortable: false,
          compact: true,
          allowOverflow: false,
          grow: 1,
          cell: (row) =>
            data["count"] >= 1 ? (
              <div className="actionsIcons">
                <Popconfirm
                  title={intl.formatMessage({ id: "msg.confirm-delete" })}
                  onConfirm={() =>
                    handleDelete(data["dataSchemas"][0]["form"], row.id)
                  }
                >
                  <DeleteTwoTone
                    twoToneColor="red"
                    title={
                      intl.formatMessage({ id: "title.label.actions.remove" }) +
                      " " +
                      intl.formatMessage({ id: "title.label.actions.record" })
                    }
                  />
                </Popconfirm>
                <Link
                  to={`/forms/${data["dataSchemas"][0]["form"]}/data/${row.id}`}
                >
                  <EditTwoTone
                    title={
                      intl.formatMessage({ id: "title.label.actions.edit" }) +
                      " " +
                      intl.formatMessage({ id: "title.label.actions.record" })
                    }
                  />
                </Link>
              </div>
            ) : null,
        },
      ];
      for (
        let i = 1;
        i < data["dataSchemas"][0]["columnFormNames"].length;
        i++
      ) {
        let selectorValue = data["dataSchemas"][0]["columnNormalizedNames"][i];
        columns.push({
          name: data["dataSchemas"][0]["columnFormNames"][i],
          selector: (row) => {
            return row[selectorValue];
          },
          sortable: true,
          sortField: selectorValue,
          reorder: true,
          allowOverflow: false,
          grow: 1,
          cell: (row) => {
            let renderValue = row[selectorValue];
            const dataType = data["dataSchemas"][0]["dataTypes"][i];
            if (renderValue && dataType === "JSON") {
              const val =
                renderValue["value"] !== undefined
                  ? renderValue["value"]
                  : renderValue;
              const values = JSON.parse(val);
              //const values = JSON.parse(renderValue['value'])
              renderValue = [];

              let photoIndex = null;
              for (
                let j = 0;
                j < data["formConfiguration"].formFields.length;
                j++
              ) {
                if (
                  data["formConfiguration"].formFields[j].nameNormalized ===
                  data["dataSchemas"][0]["columnNormalizedNames"][i]
                ) {
                  photoIndex = j;
                }
              }

              for (let j = 0; j < values.length; j++) {
                if (
                  photoIndex &&
                  data["formConfiguration"]["formFields"][photoIndex]?.type ===
                    FORM_FIELD_TYPES.PHOTO_CHECKBOX
                ) {
                  if (values[j].toString() === "true") {
                    renderValue.push(
                      <Image
                        key={j}
                        width={150}
                        src={
                          data["formConfiguration"]["formFields"][photoIndex]
                            ?.photoValues[j]
                        }
                      />
                    );
                  }
                } else {
                  renderValue.push(<Tag key={j}>{values[j].toString()}</Tag>);
                }
              }
            } else if (renderValue && dataType === "BOOLEAN") {
              renderValue = renderValue.toString();
            } else if (renderValue && dataType === "DATE") {
              renderValue = moment(renderValue).format("YYYY-MM-DD");
            } else if (renderValue && dataType === "TIME") {
              renderValue = moment(renderValue).format("HH:mm:ss");
            } else if (renderValue && dataType === "PHOTO") {
              renderValue = <Image width={150} src={`data:${renderValue}`} />;
            }
            return data["count"] >= 1 ? (
              <div style={{ minWidth: 60 }}>{renderValue}</div>
            ) : null;
          },
        });
      }
      setColumns(columns);

      // Update state
      dispatch({
        type: FORM_DATA_ACTION_TYPES.LIST_FORM_DATA,
        payload: data,
      });
      return columns;
    } catch (err) {
      console.log(err);
      dispatch({
        type: FORM_DATA_ACTION_TYPES.ERROR_FORM_DATA,
        payload: err.message,
      });
    }
  };

export const addFormData =
  (history, formData, setFormDataCreated, formId) => async (dispatch) => {
    try {
      dispatch(setLoading());
      // Send request and get data
      const { data } = await axiosInstance(history, dispatch).post(
        `/form/${formId}/data`,
        formData
      );
      // Update state
      dispatch({
        type: FORM_DATA_ACTION_TYPES.ADD_FORM_DATA,
        payload: data,
      });
      setFormDataCreated(true);
    } catch (err) {
      console.log(err);
      const error = await err.response.data;
      dispatch({
        type: FORM_DATA_ACTION_TYPES.ERROR_FORM_DATA,
        payload: error,
      });
    }
  };

export const getFormData = (history, formId, id) => async (dispatch) => {
  try {
    dispatch(setLoading());
    // Send request
    const { data } = await axiosInstance(history, dispatch).get(
      `/form/${formId}/data/${id}`
    );
    // Update state
    dispatch({
      type: FORM_DATA_ACTION_TYPES.SHOW_FORM_DATA,
      payload: data,
    });
    return data;
  } catch (err) {
    console.log(err);
    dispatch({
      type: FORM_DATA_ACTION_TYPES.ERROR_FORM_DATA,
      payload: err.message,
    });
  }
};

export const editFormData =
  (history, formId, id, formData, setFormDataCreated) => async (dispatch) => {
    try {
      await dispatch(setLoading());
      // Send request and get data
      await axiosInstance(history, dispatch).patch(
        `/form/${formId}/data/${id}`,
        formData
      );
      // Update state
      await dispatch(clearLoading());
      await setFormDataCreated(true);
    } catch (err) {
      console.log(err);
      dispatch({
        type: FORM_DATA_ACTION_TYPES.ERROR_FORM_DATA,
        payload: err,
      });
    }
  };

export const deleteFormData = (history, formId, id) => async (dispatch) => {
  try {
    dispatch(setLoading());
    // Send request
    await axiosInstance(history, dispatch).delete(`/form/${formId}/data/${id}`);
    // Update state
    dispatch({
      type: FORM_DATA_ACTION_TYPES.DELETE_FORM_DATA,
      payload: id,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: FORM_DATA_ACTION_TYPES.ERROR_FORM_DATA,
      payload: err.message,
    });
  }
};

export const exportFormData =
  (history, formId, filters) => async (dispatch) => {
    try {
      let queryParameters = {};
      Object.keys(filters).map(
        (key) =>
          (queryParameters[key] =
            filters[key] == null ? filters[key] : filters[key][0])
      );
      const params = {
        ...queryParameters,
      };
      // Send request
      const { data } = await axiosInstance(history, dispatch).get(
        `/form/${formId}/export`,
        { params }
      );
      return data;
    } catch (err) {
      console.log(err);
      dispatch({
        type: FORM_DATA_ACTION_TYPES.ERROR_FORM_DATA,
        payload: err.message,
      });
    }
  };

export const exportXlsxFormData = (history, formId) => async (dispatch) => {
  try {
    // Send request
    const { data } = await axiosInstance(history, dispatch).get(
      `/form/${formId}/exportXlsx`
    );
    return data;
  } catch (err) {
    console.log(err);
    dispatch({
      type: FORM_DATA_ACTION_TYPES.ERROR_FORM_DATA,
      payload: err.message,
    });
  }
};

export const downloadFormData =
  (history, formId, filename) => async (dispatch) => {
    try {
      // Wait for file request
      const timer = (ms) => new Promise((res) => setTimeout(res, ms));
      while (
        (
          await axiosInstance(history, dispatch).head(
            `/form/${formId}/download/${filename}`
          )
        ).status !== 200
      ) {
        await timer(10000);
      }
      // Send request
      return await axiosInstance(history, dispatch).get(
        `/form/${formId}/download/${filename}`
      );
    } catch (err) {
      console.log(err);
      dispatch({
        type: FORM_DATA_ACTION_TYPES.ERROR_FORM_DATA,
        payload: err.message,
      });
    }
  };

export const downloadXlsxFormData =
  (history, formId, filename) => async (dispatch) => {
    try {
      // Wait for file request
      const timer = (ms) => new Promise((res) => setTimeout(res, ms));
      while (
        (
          await axiosInstance(history, dispatch).head(
            `/form/${formId}/downloadXlsx/${filename}`
          )
        ).status !== 200
      ) {
        await timer(10000);
      }
      // Send request
      return await axiosInstance(history, dispatch).get(
        `/form/${formId}/downloadXlsx/${filename}`,
        {
          responseType: "blob",
        }
      );
    } catch (err) {
      console.log(err);
      dispatch({
        type: FORM_DATA_ACTION_TYPES.ERROR_FORM_DATA,
        payload: err.message,
      });
    }
  };

export const getFormConfiguration = (history, formId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    // Send request
    let params = {};
    params["max"] = 1;
    const { data } = await axiosInstance(history, dispatch).get(
      `/form/${formId}/data`,
      { params }
    );
    // Update state
    await dispatch({
      type: FORM_DATA_ACTION_TYPES.GET_FORM_CONFIGURATION,
      payload: data.formConfiguration,
    });
    return data.formConfiguration;
  } catch (err) {
    console.log(err);
    dispatch({
      type: FORM_DATA_ACTION_TYPES.ERROR_FORM_DATA,
      payload: err.message,
    });
  }
};

export const getFormDataDateRange = (history, formId) => async (dispatch) => {
  try {
    dispatch(setLoading());

    const { data } = await axiosInstance(history, dispatch).get(
      `/form/${formId}/dataDateRange`
    );

    //check if is there any controlled error from server
    if (data.data?.errors) {
      throw new Error(data.data.errors.join(", "));
    }

    // Update state
    await dispatch({
      type: FORM_DATA_ACTION_TYPES.GET_FORM_DATA_DATERANGE,
      payload: data.data.dataDateRange,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: FORM_DATA_ACTION_TYPES.ERROR_FORM_DATA_DATERANGE,
      payload: err?.message ? err.message : err,
    });
  }
};
