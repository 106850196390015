/*
    Reference for standard week dates to be used:
    https://en.wikipedia.org/wiki/ISO_week_date
*/

import { Form, message, Skeleton } from "antd";
import { useIntl } from "react-intl";
import { useEffect, useState, useContext, Suspense } from "react";

import { getDataDateRange } from "../../../redux/dataAnalysis/dataAnalysisActions";

import { getFieldPath, getIsFieldChanged } from "../utils/utilsForm";

import { DataAnalysisFormContext } from "../../../components/dataAnalysis/DataAnalysisForm";

//using moment with DatePicker
//https://ant.design/docs/react/use-custom-date-library#datepicker
import moment from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import generatePicker from "antd/es/date-picker/generatePicker";
const DatePicker = generatePicker(momentGenerateConfig);
//updating locale for week
moment.locale("en-us", {
  week: {
    dow: 1, // first day of week for dateRange picker!
    doy: 4,
  },
});

export const FormItemDateRangePicker = (props) => {
  //local constants
  const intl = useIntl();
  const {
    analysisId,
    changedField,
    configEditFieldName,
    configView,
    form,
    view,
  } = props;

  const { isXDateType } = useContext(DataAnalysisFormContext);

  const formItemComponentName = "dateRange";

  const { RangePicker } = DatePicker;

  const [validDateRange, setValidDateRange] = useState(null);

  const dateScalePath = getFieldPath(
    [configEditFieldName],
    ["dateTimeOptions", "dateScale"]
  );

  useEffect(
    function ue_getValidDateRange() {
      if (!view || !analysisId) return;

      getDataDateRange(analysisId)().then((dataObj) => {
        const { data, errors } = dataObj;
        if (data?.length === 0) {
          message.info("All dates are valid for Date Range picker!");
          message.info(errors);
          setValidDateRange({ min: null, max: null });
        } else {
          setValidDateRange({ min: data[0].min, max: data[0].max });
        }
      });
    },
    [view, analysisId]
  );

  const getDateRangePickerFormat = (dateScale) => {
    /*
            reference: https://day.js.org/docs/en/display/format
        */
    let dateRangePickerFormat_;
    switch (dateScale) {
      case "minute":
        dateRangePickerFormat_ = "YYYY-MM-DD HH:mm";
        break;
      case "hour":
        dateRangePickerFormat_ = "YYYY-MM-DD HH";
        break;
      case "date":
      case "day":
        dateRangePickerFormat_ = "YYYY-MM-DD";
        break;
      case "week":
        dateRangePickerFormat_ = "GGGG-WW";
        break;
      case "month":
        dateRangePickerFormat_ = "YYYY-MMM";
        break;
      case "quarter":
        dateRangePickerFormat_ = "YYYY-\\QQ";
        break;
      case "year":
        dateRangePickerFormat_ = "YYYY";
        break;
      default:
        dateRangePickerFormat_ = null;
    }

    return dateRangePickerFormat_;
  };
  const [dateRangePickerFormat, setDateRangePickerFormat] = useState();

  useEffect(() => {
    if (!configView) return;
    setDateRangePickerFormat(
      getDateRangePickerFormat(configView?.dateTimeOptions?.dateScale)
    );
  }, [configView]);

  useEffect(() => {
    if (!changedField) return;

    const fieldPath = dateScalePath;

    const isFieldChanged = getIsFieldChanged(fieldPath, changedField.name);
    if (isFieldChanged) {
      form.resetFields([[configEditFieldName, formItemComponentName]]);
      setDateRangePickerFormat(getDateRangePickerFormat(changedField.value));
    }

    //eslint-disable-next-line
  }, [changedField]);

  /*TODO: Include a default value for showTime options.*/

  return (
    <Suspense fallback={<Skeleton active={true} />}>
      <div id={"pickDateRange"}>
        <Form.Item
          name={[configEditFieldName, formItemComponentName]}
          label={intl.formatMessage({
            id: "label.PickDateRange.FormItem.label",
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "msg.input-required.Analysis.dateRange",
              }),
            },
          ]}
          hidden={false}
          dependencies={[dateScalePath]}
          initialValue={undefined}
        >
          {(() => {
            const picker =
              ["date", "minute", "hour", "day"]?.includes(
                form.getFieldValue(dateScalePath)
              ) || form.getFieldValue(dateScalePath) === undefined
                ? "date"
                : form.getFieldValue(dateScalePath);

            const showTime = ["hour"]?.includes(
              form.getFieldValue(dateScalePath)
            )
              ? {
                  format: "HH",
                }
              : ["minute"]?.includes(form.getFieldValue(dateScalePath))
              ? {
                  format: "HH:mm",
                }
              : false;

            const isDisabled =
              view && isXDateType && !form.getFieldValue(dateScalePath);

            return (
              <RangePicker
                picker={picker}
                showTime={showTime}
                format={dateRangePickerFormat}
                disabled={isDisabled}
                disabledDate={(current) => disabledDate(current, validDateRange)}
              />
            );
          })()}
        </Form.Item>
      </div>
    </Suspense>
  );
};

/**
   * It takes a single argument date1, which is expected to be a JavaScript Date object.
   *The purpose of this function is to create and return a new Date object that
   *represents the same calendar date as date1 but with the time set to midnight
   *(the start of that date).
   *
   */
   export const toDateDate = (date1) => {
    let actualDate;
    if (typeof date1 === 'object' && date1 instanceof Date) {
      actualDate = date1;
    } else if (typeof date1 === 'string') {
      actualDate = new Date(date1);
    }
    
    const d1 = new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate());

    return d1;
  };

/*
  NOTE: This function does not apply to time scale but to minimum a day scale.

  The selected code defines a function named disabledDate. 
  This function is used to determine whether a given date (current) 
  should be disabled in a date range picker component. Here's a breakdown 
  of what the function does:

  It first creates a variable endDate which represents the current date (without time).
  Then it checks if validDateRange is null or both min and max properties of 
  validDateRange are null. If either condition is true, it returns true if 
  current date is after endDate, effectively disabling all future dates.
  If validDateRange is not null and both min and max are not null, it means 
  there is a specific date range that should be enabled. In this case, it creates 
  startDate and endDate variables representing the start and end of the valid 
  date range respectively.
  Finally, it returns true if current date is before startDate or after endDate, 
  effectively disabling all dates outside the valid range.
  In summary, this function is used to disable certain dates in a date range 
  picker based on the validDateRange state.
*/
export const disabledDate = (current, validDateRange) => {
  let endDate = toDateDate(new Date());
  //return current && current.isAfter(endDate);
  if (
    validDateRange === null ||
    (validDateRange?.min === null && validDateRange?.max === null)
  ) {
    return current && current.startOf("day").isAfter(endDate);
  } else {
    const startDate = toDateDate(new Date(validDateRange.min));
    endDate = toDateDate(new Date(validDateRange.max));
    return (
      current &&
      (current.startOf("day").isBefore(startDate) ||
        current.startOf("day").isAfter(endDate))
    );
  }
};

export default FormItemDateRangePicker;
